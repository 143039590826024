* {
  scroll-behavior: smooth;
}

html,
body {
  height: 100vh;
}

body {
  @include scrollbars();
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sp-main {
  height: 100%;
  overflow: hidden;
  flex: 1;

}

.sp-sidebar {
  width: 13.75rem;
  height: 100%;
}

.sp-section {
  flex: 1;
}

// Scroll
.scroll-y {
  min-height: 0;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  @include scrollbars();
}

.scroll-x {
  min-width: 0;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  @include scrollbars();
}

.scroll-auto {
  min-height: 0;
  min-width: 0;
  overflow-y: auto !important;
  overflow-x: auto !important;
  @include scrollbars();
}

// Form


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: $black-rock !important;
  box-shadow: 0 0 0 1000px $black-rock inset !important;
  appearance: none !important;
  background-image: unset !important;
  border-color: $black-rock !important;
}

input:-internal-autofill-selected {
  -webkit-text-fill-color: $white !important;
}

//Form Control

.form-control {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 600;

  &:focus,
  &:hover {
    background-color: $black-rock;
    border-color: $black-rock;
    color: $input-color;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $black-rock;
  }
}

//Button

[class*="btn-"]:focus,
[class*="btn-"].focus,
[class*="btn-outline-"]:focus,
[class*="btn-outline-"].focus {
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.show>.btn.dropdown-toggle:focus .btn {
  box-shadow: none;
}

.btn-secondary {
  &:hover {
    background-color: $turquoise-blue;
    border-color: $turquoise-blue;
  }
}

// checkbox/radio

.form-check-input {
  width: 1.625rem;
  height: 1.625rem;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid $blue-haze;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;

  &.form-check-input-sm {
    width: 16px;
    height: 16px;
    border-color: $white;
  }
}

.form-check-input:checked {
  border-color: $blue-haze;
}

// Checkbox
.form-check-input[type='checkbox'] {
  border-radius: 3px;
}

.form-check-input:checked[type='checkbox'] {
  background-color: $ebony-clay;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABHNCSVQICAgIfAhkiAAAA69JREFUaEPtl09IFFEcx9/MblJ7KBXXQxZhBY0dOnlpc1fdLLpEBkEkRRqYl4LoEMwWMri0EAjdgvBgRIQHwcCUpIPloRCkg10KIj1YGSv+w1DnzXuv38zsrpshzpsZh1VGWAT3vXnz+X2+7/eeAtphP8IO40E+UKEb9Q35hjyugB85jwvOvZxviLtkHk/wDXlccO7lfEPcJfN4QsEa6jzbLbFl2rVLpZfujLb+tlqXggTqPP9SolgbQRoNI0y/B9VA7O7H6z+sQBUc0KPLPVUIoxGB0DKECRI0iphKhu+9uxHfdkCp5l5JJMIIQIAZgsAQAkNTlOCo/KZlclsBpW71S4LGMjDUMANQU1SlUfnVFUswOnBBRC4lD0loheox0/dMFmaaIeGk/OyiZRhbQMrEXLFSWTJvRb+VMSllWIJovYdPubFnCIOokV+Ikpj85MI3K8/IH8Nl6P7kYkQk2iDEoaXjWFkf72Lrx6c6P0hU1XJ7xoDBZBqiF5Ufn+OG4TL0IL1chwjRYfbAogQRdjV5tLTHLlTq6ZhEVzDAsDDLdDMwkxaREJGVelswXECJxdU+UaONZis1KokQpdeSh0tf8EKlusckoiGIGTNjZjaAGbAfTSTqv/A+z1bkFMZ24z94ABaP50ExsNaUrLRuSukdl8RV45wxWrNeHDCUDjASS9yOOILhMqQPNqCWAQozE4rAoYcJg9/NyUMlzzerrNL/VRIodDOcPWcM0+kgprFEW7VjGG6gHNSKBqYyUHpcNMqg3QLUvg2hlOEJCV7e2DPZYsCc2SChpxJNJ1yBsQWUg1olA2AmztbODdPUgf+hlLGfcM6ocDdDYdj4mT1DZ2F8XXtj1efNzPJ8z9W28x9sxA8zMEXixhXF3NhMJLStY//eruxYZXxGgqvLWms2G8qsSFld+5kjrsLYNpR7WQOKvEUU1eR3P1EjN3UoZWJGIio0APPWbJrRyEKAomh75KDrMI6BMvELaZgMwjWldh3UQ6bRVtgz5blzBtN5UdVOK9UVn3hixDPWduTWxS8EpobgalijR880kYthds8sBFQSV46HtwzGFUN58VuDgqaXhTL+n8FkKUhJrVJRvKUwrgJl45czpf/BbBRLIqYNybLQKE907I51JXIbxg+hJWgQDclQkScwrhv6N37sNVw05WSR4BnMlgHZjYsb81yPnBsv5eQZPpCT6nkx1zfkRZWdrOEbclI9L+b6hryospM1fENOqufFXN+QF1V2ssaOM/QX0kQfU9/19oEAAAAASUVORK5CYII=');
  background-size: 24px;
  background-position-y: 0px;
  box-shadow: inset 0px 3px 6px #8089B7, 0px 3px 6px rgba(0, 0, 0, 0.612);
}

.form-check-input-sm:checked[type='checkbox'] {
  background-size: 14px auto;
  box-shadow: unset;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: none;
}


.section-heading {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: $blue-haze;
}

.organization-heading {
  font-size: 1.75rem;
  line-height: 1.875rem;
  color: $blue-haze;
}

.back-page-arrow {
  font-size: 2rem;
  color: $blue-haze;
  cursor: pointer;
}

.text-truncate-three-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-truncate-four-line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

//table

.sp-table {

  thead,
  tbody {
    tr {

      th,
      td {
        padding: 1rem;
      }
    }
  }

  thead {
    tr {
      th {
        border: none;
        color: $secondary;
        font-size: 0.75rem;
        line-height: 0.875rem;
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      td {
        color: $blue-haze;
        font-size: 0.875rem;
        line-height: 1.5rem;
        background-color: $black-rock;
      }

      &:not(:first-child) {
        td {
          border-top: 1px solid $steel-gray;
        }
      }

      &:first-child {
        td {
          border-top: none;

          &:first-child {
            border-top-left-radius: 0.25rem;
          }

          &:last-child {
            border-top-right-radius: 0.25rem;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 0.25rem;
          }

          &:last-child {
            border-bottom-right-radius: 0.25rem;
          }
        }
      }
    }
  }

  &.test-cases-table {
    border-collapse: separate;
    border-spacing: 0.3 0.3125rem;

    thead,
    tbody {
      tr {

        th,
        td {
          padding: 0.5rem 1rem;
        }
      }
    }

    thead {
      tr {
        th {
          color: $blue-haze;
        }
      }
    }

    tbody {
      tr {

        td {
          color: $white;
        }

        td {
          &:first-child {
            border-radius: 0.25rem 0 0 0.25rem;
          }

          &:last-child {
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }

}

// Pagination
.sp-pagination {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    padding: 0.25rem 0.75rem;
    border-left: 1px solid $steel-gray;
    background-color: $black-rock;

    &:last-child {
      border-right: 1px solid $steel-gray;
    }

    a {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: $blue-haze;
      text-decoration: none;
    }

    &.previous,
    &.next {
      i {
        font-size: 0.8125rem;
        color: $blue-haze;
        vertical-align: middle;
      }

      span {
        font-size: 0.625rem;
        line-height: 0.75rem;
      }
    }

    &.previous {
      border-radius: 0.3125rem 0 0 0.3125rem;
    }

    &.next {
      border-radius: 0 0.3125rem 0.3125rem 0;
    }
  }

  .sp-pagination__link--active a {
    color: $white;
  }

  .sp-pagination__link--disabled a {
    opacity: 0.29;
  }
}

//Modal backdrop blur

body.modal-open .sp-main {
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}

//left modal
.left_modal {
  position: fixed;
  z-index: 99999;

  .modal-header-wrap {

    padding: 2rem 3rem;

    h6 {
      color: $primary;
      font-size: 1.25rem;
      line-height: 1.5625rem;
      font-weight: 500;
    }

    .close-button {
      right: 2.625rem;
    }
  }
}

.modal.left_modal {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 27.125rem;
    max-width: 38.125rem;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
}

/*Left*/
.modal.left_modal.fade {
  .modal-dialog {
    left: -50%;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
  }
}

.modal.left_modal.fade.show {
  .modal-dialog {
    left: 0;
    // -webkit-box-shadow: 8px 0px 6px rgba(0, 0, 0, 0.161);
    // box-shadow: 8px 0px 6px rgba(0, 0, 0, 0.161);
  }
}

// Utility classes
.flex-1 {
  flex: 1;
}

.woot-widget-bubble.woot-elements--right {
  bottom: 80px !important;
}

//loader spinner
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, $alpha: 0.7);
  z-index: 9999;

  .loader {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.errFontSize {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.invalid-feedback{
  font-size: 1rem;
}

button:disabled {
  cursor: not-allowed;
  color: white !important;
}

input:disabled{
  cursor: not-allowed;
}

.react-select-container {
  .react-select__placeholder {
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    color: $input-placeholder-color;
  }
}

.gap-1\/2 {
  gap: 0.125rem !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.pad-5 {
  padding: '0.5rem 1rem'
}