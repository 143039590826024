.box {
  background-color: black;
  height: 100vh;
  width: 100%;
  font-family: "Montserrat";
}

.box Input {
  padding: 15px;
  background-color: whitesmoke;
  font-size: large;
  width: 100%;
  color: black;
}

.box Form {
  position: relative;
}

.box Form Label {
  position: absolute;
  top: 7px;
  left: 32px;
  font-size: large;
  color: rgb(100, 100, 100);
  transition: .3s;
  pointer-events: none;
}

.box Input:hover {
  background-color: whitesmoke;
  color: black;
}

input:not(:placeholder-shown) + Label,
.box Input:hover + Label {
  top: -25px;
  color: whitesmoke;
  font-size: 15px;
  font-weight: bold;
  font-family: "Montserrat";
}

.errorToast{
  position: absolute;
  top:30px;
  left:30px;
}

.formCard{
  padding: 8%;
  padding-top: 25%;
}
