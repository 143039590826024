@import "~assets/stylesheets/core";

.card.sp-card {
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.161);
  background-color: $black-rock;


  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  
}

