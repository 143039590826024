@import "~assets/stylesheets/core";

.submit-test-modal {
  top: 15% !important;

  .modal-content {
    border-radius: 5px;
    background: $gray24;
  }

  .submission-modal-header {
    padding: 2rem 1.5rem;
    color: $secondary;
    font-size: 3.875rem;
  }

  .submission-modal-body {
    padding: 2rem 1.5rem;
    color: $blue-haze;
    border-bottom: 0 none;
  }

  .submission-modal-footer {
    border-top: 0 none;
  }
}

.testcase-result-modal {
  top: 15% !important;

  .modal-content {
    border-radius: 5px;
    background: $gray24;
  }

  .testcase-result-modal-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $white;
    span {
      color: $secondary;
      font-size: 1.25rem;
    }
    i {
      font-size: 1.875rem;
    }
  }

  .testcase-result-modal-body {
    padding: 2rem 1.5rem;
    color: $blue-haze;
    border-bottom: 0 none;
  }

  .testcase-result-modal-footer {
    border-top: 0 none;
  }
}

.finish-test-modal {
  top: 15% !important;

  .modal-content {
    border-radius: 5px;
    background: $gray24;
  }

  .finish-test-modal-body {
    padding: 2rem 1.5rem;
    color: $blue-haze;
    border-bottom: 0 none;
  }

  .finish-test-modal-footer {
    border-top: 0 none;
  }
}

.output-section {
  padding: 0rem 1rem 1rem;
  background-color: $ebony-clay;

  h6 {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: $white;
    text-transform: capitalize;
    margin-top: 3.75rem;
  }

  span {
    color: $baby-blue;
  }

  .custom-input-btn {
    background-color: $steel-gray;
    border: none;
    color: $secondary;
    &:hover, &:focus, &:active {
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  .output-run-btn {
    text-transform: uppercase;
  }
}

.output-btn-wrapper {
  left: 0;
  width: 100%;
  z-index: 9999;
  .custom-input-btn {
    background: url('../../assets/images/custom-input-bg1.png') no-repeat;
    background-size: cover;
    width:17.5rem;
    height:3rem;

    span {
      color: $white;
      font-size: 0.875rem;
      line-height: 1.875rem;
    }
  }
  .custom-output-section {
    background-color: $steel-gray;
    box-shadow: 0px -7px 6px rgba(0, 0, 0, 0.161);
    height: 100vh;
    display: none;

    .output-header {
      span {
        color: $warning;
        font-size: 1.1rem;
      }

      i {
        color: $secondary;
        font-size: 1.3rem;
      }

    }

    .form-group {
      flex: 1;

      .form-control {
        background-color: transparent;
      }
    }
    .close-button {
      position: unset;
    }

    .run-btn {
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }

  &.toggle-wrapper {
    .custom-output-section { 
      display: block;
    }
  }
}

.custom-button-wrapper {
  left: 0;
  bottom: 0; 
  width: 100%;
  z-index: 9999;
  .custom-input-wrapper {
    position: relative;
    z-index: 1001;
    background: url('../../assets/images/custom-input-bg1.png') no-repeat;
    background-size: cover;
    width:17.5rem;
    height:3rem;

    span {
      color: $secondary;
      font-size: 1rem;
      line-height: 1.875rem;
    }
  }
  .custom-input-section {
    background-color: $steel-gray;
    box-shadow: 0px -7px 6px rgba(0, 0, 0, 0.161);
    border-radius: 1.1875rem 1.1875rem 0 0;
    height: 50vh;
    display: none;

    .form-group {
      flex: 1;

      .form-control {
        background-color: transparent;
      }
    }
    .close-button {
      position: unset;
    }

    .run-btn {
      bottom: 1.5rem;
      left: 1.5rem;
      right: unset !important;
    }
  }

  &.toggle-wrapper {
    .custom-input-section { 
      display: block;
    }
  }
}

.candidate-output-section{
  border-radius: 1.25rem;
}
