@import "~assets/stylesheets/core";

.candidate-section {
  height: 100vh;
  padding: 0 2rem 1rem;
}

.drive-title {

  border-left: 2px solid $black-rock;
  span {
    font-size: 1.25rem;
    line-height: 2rem;
    color: $white;
  }
}

.add-candidate-btn {
  &.btn {
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: $white;
    border-radius:0 0 1.25rem 1.25rem;
    box-shadow: 0px 3px 6px rgba($black, 0.161);
    background: transparent linear-gradient(180deg, #252836 0%, #777982 12%, #393C4D 31%, #252836 100%) 0% 0% no-repeat padding-box;

    &:active, &:hover, &:focus {
      background: transparent linear-gradient(0deg, #B94FC2 0%, #79347E 75%, #A346AB 93%, #5D2861 100%) 0% 0% no-repeat padding-box;
      color: $white;
    }
  }
}

.absent-candidate-btn {
  &.btn {
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: $white;
    border-radius:0 0 1.25rem 1.25rem;
    box-shadow: 0px 3px 6px rgba($black, 0.161);
    background: transparent linear-gradient(180deg, #252836 0%, #777982 12%, #393C4D 31%, #252836 100%) 0% 0% no-repeat padding-box;

    &:active, &:hover, &:focus {
      background: transparent linear-gradient(0deg, #B94FC2 0%, #79347E 75%, #A346AB 93%, #5D2861 100%) 0% 0% no-repeat padding-box;
      color: $white;
    }

    &:disabled{
      cursor: not-allowed !important;
    }
  }
}

.select-all-checkbox {
  width: 1rem !important;
  height: 1rem !important;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid $blue-haze;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  margin-right: 0.75rem !important;
  margin-top: 0rem !important;
  position: relative !important;

}

.select-all-checkbox:checked[type='checkbox']{
  background-size: 16px !important;
}

.resend-invitation{
  font-size: 2em;
  .send-icon {
    color: $secondary;
    cursor: pointer;
  }
  margin-left: 1rem;
}

.candidates-tab{
  font-size: 20px !important;
}