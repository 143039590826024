.myClass{
  width: 100% ;
  height: 10vh;
}
.custom-circle.btn-circle {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
}
.custom-height {
  height: 10vh;
  font-family: "Montserrat";
}
.custom-font-size{
  font-size: 17px;
}