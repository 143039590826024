@import "~assets/stylesheets/core";

.add-problem-section {
  height: 100vh;
}

.problem-col-wrapper {

  .problem-col {
    &:first-child {
      flex: 0 0 70%;
    }
    &:last-child {
      flex: 0 0 30%;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: url('../../../../assets/images/divider@2x.png') no-repeat;
        width: 4px;
        height: 100%;
        background-size: cover;
        background-position: top;
      }
    }
  }
}

.add-problem-form {
  .form-group {
    label {
      color: $secondary;
      font-size: 1rem;
      line-height: 1.5625rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .select-input-type {
      font-size: 0.875rem;
      line-height: 2.4375rem;
      font-weight: 600;
      padding: 0.25rem 1rem;
    }
    .dropdown {
      line-height: 2rem;
      border-color: green !important;
    }
  }
}
.form-group-col-wrapper {
  
  margin: 0 -0.75rem 1rem;
  .form-group-col {
    .form-group {
      padding: 0 0.75rem;
    }

    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 0 0 60%;
    }
  }
}

.tabContent {
  .tabPane {
  &.active{
    display: flex;
  }
}
}