@import "~assets/stylesheets/core";

.credit-points-wrapper {
  color: $blue-haze;
  font-size: 1.750rem;
  .points{
    color: white;
  }
  // line-height: 1.625rem;
}

.profile-detail-list-wrap {
  .profile-detail-list {
    border-bottom: 1px solid rgba($white, 0.24);

    span, label {
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }

    label {
      color: $blue-haze;

      &.candidate-name {
        color: $white;
        font-size: 1.5625rem;
        line-height: 1.875rem;
      }
    }

    span {
      color: $white;
      font-weight: 500;
    }

    .profile-edit-btn {
      color: $secondary;
      font-size: 1.25rem;
      line-height: 1.625rem;

      i {
        font-size: 2rem;
        vertical-align: middle;
      }
    }
  }
}

.modal-footer {
  .logout-btn{
    color: $white;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    background-color: #B94FC2;

    >i {
      color: #ffffff;
      font-size: 2rem;
      vertical-align: middle;
    }

    &:hover, &:focus,&:active {
      color: $white;
    }
  }
}
.confirmation-modal {
  background-color: white;
  border-radius: 0.25rem;
  .modal-body, .modal-header, .modal-footer {
    background-color: $ebony-clay;
  }
}
.edit-pen{
  font-size: 2rem;
  cursor: pointer;
}
.profile-icon{
  width: 50px;
  height: 50px;
}
.profile-div{
  justify-content: space-between !important;
}
.profile-box{
  overflow: auto;
  background-color: whitesmoke;
  border: 50%;
}

.profile-modal{
  width: 36rem !important;
}
