@import "~assets/stylesheets/core";

.organization-list-section {
  padding: 2rem 1rem 0;
  height: 100vh;
}

.organization-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;


  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.organization-content {
  flex: 1;

  .organization-description {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $blue-haze;
    height: 60px;
  }
}

.organization-info {
  border-top: 2px solid $steel-gray;
  border-radius: 0 0 0.25rem 0.25rem;
  // bottom: 0;

  .organization-info-tile {
    background-color: $black-rock;
    flex: 1;
  }

  .organization-tile-box {
    label {
      font-size: 0.75rem;
      line-height: 1.1875rem;
      color: $blue-haze;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: $white;
    }

    &:first-child {
      flex: 0 0 50%;
    }

    &:not(:first-child) {
      flex: 0 0 50%;
      border-left: 2px solid $steel-gray;
    }
  }
}

.credits-modal {
  background-color: white;
  border-radius: 0.25rem;

  .modal-body,
  .modal-header,
  .modal-footer {
    background-color: $ebony-clay;
    justify-content: center;

    .modal-header-title {
      font-size: 1.7rem;
    }
  }
}

.add-credits-form {
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      color: $secondary;
      font-size: 1.2rem;
      line-height: 1.5625rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .select-input-type {
      font-size: 0.875rem;
      line-height: 2.0rem;
      font-weight: 600;
      padding: 0.25rem 1rem;
    }
  }
}

.form-group-col-wrapper {
  margin: 0 -0.75rem 1rem;

  .form-group-col {
    .form-group {
      padding: 0 0.75rem;
    }

    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 0 0 60%;
    }
  }
}
