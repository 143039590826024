@import "~assets/stylesheets/core";

.card-heading {
  font-size: 1rem;
  line-height: 1.0625rem;
  color: $secondary;
  overflow-wrap: anywhere;
}

.drive-content {
  padding: 0 0.5rem 0.75rem;
}
.icon-delete {
  color:$secondary;
  font-size: 1.5rem;
  cursor: pointer;
}
.drive-date-details {

  i {
    font-size: 1.125rem;
  }
  .icon-calendar {
    color: $sunglow;
  }
  .icon-arrow-right {
    color:$secondary;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    color: $blue-haze;
  }
}

.tile-button-wrapper {
  border-top: 1px solid $steel-gray;
  .tile-btn {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: $white;
    flex: 1;
    background: transparent linear-gradient(180deg, #FFC92400 0%, #FFC92454 82%, #FFC924 89%, #FFC924 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: luminosity;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;

    &:first-child {
      border-radius: 0 0 0 0.25rem;
    }

    &:not(:first-child) {
      border-left: 1px solid $steel-gray;
      border-radius: 0 0 0.25rem  0;
    }

    &.-candidates-btn {
      &:hover, &:focus, &:active {
        background: transparent linear-gradient(180deg, #7BFAFD00 0%, #7BF9FC38 72%, #7BF9FCC7 85%, #79F6F9FC 100%) 0% 0% no-repeat padding-box;
      }
    }


    &.-result-btn {
      &:hover, &:focus, &:active {
      background: transparent linear-gradient(180deg, #FFC92400 0%, #FFC92454 82%, #FFC924 89%, #FFC924 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

}
