@import "~assets/stylesheets/core";

.code-editor-wrapper {
  position: relative;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   background: url('../../assets/images/divider@2x.png') no-repeat;
  //   width: 0.5rem;
  //   height: 100%;
  //   background-size: cover;
  //   background-position: top;
  // }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   background: url('../../assets/images/divider@2x.png') no-repeat;
  //   width: 0.5rem;
  //   height: 100%;
  //   background-size: cover;
  //   background-position: top;
  // }

}

.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input, .margin-view-overlays {
  background-color: $steel-gray !important;
}

.decorationsOverviewRuler {
  display: none;
}

.monaco-editor {
  // width: 100% !important;

  // .view-overlays {
  //   .current-line {
  //     border: none !important;
  //   }
  // }

  .line-numbers, .line-numbers.active-line-number {
    color: $blue-haze !important;
    font-size: 0.9375rem;
    font-weight: 500;
  }

  // .scroll-decoration {
  //   box-shadow: unset !important;
  // }

  // .margin {
  //   background-color: transparent !important;
  // }
}

// .mtk1 {
//   color: $white !important;
//   font-size: 0.9375rem;
//   font-weight: 500;
// }

// .overflow-guard {
//   width: 100% !important;
// }

// .monaco-scrollable-element {
//   width: calc(100% - 74px) !important;
// }