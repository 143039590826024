@import "~assets/stylesheets/core";

.filter-box{
  background-color: $ebony-clay;
  border-radius: 0.25rem;
  padding: 0.18rem;

  p {
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: $blue-haze;
  }
  span {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    color:$white;
  }

  .add-filter {
    border: 1px solid #39FAFF;
    border-radius: 5px;
    min-width: 5rem;
    text-align: center;
  }
}