@import "~assets/stylesheets/core";

.test-case-section-wrap {
  h6 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: $primary;
  }

  .form-group {
    label {
      color: $secondary;
      font-size: 1rem;
      line-height: 1.5625rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
}

.test-case-container{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.close-button{
  i{
    font-size: 1.5rem;
  }
}
