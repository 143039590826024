.new-drive-section {
  padding: 2rem;
  height: 100vh;
  flex: 1;

  input[type="datetime-local" i]::-webkit-calendar-picker-indicator { 
    background: url('../images/input-calendar.svg') no-repeat;
  }
}

.new-drive-wrapper {
  margin: 2rem 0;
  flex: 1;
  .new-drive-col {
    min-width: 22.5rem;
    flex: 1;
    height: 100%;
    .assessment{
      position: relative;
    }
  }
}

.new-drive-form {
  height: 100vh;


  label {
    color: $secondary;
    font-weight: 600;
    font-size: 1rem;
  }

  .form-group {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.new-drive-content-head {

  i {
    font-size: 1.875rem;
    color: $white;
  }

  span {
    font-size:0.75rem;
    line-height: 0.75rem;
    color: $secondary;
    border-radius: 0.25rem;
    border: 1px solid $secondary;
  }
}

.new-drive-content-body {

  .list-wrap {
    background-color: $ebony-clay;
    border-radius: 0.25rem;
  }

  .list-item {
    label {
      color:$white;
    }

    .form-check {
      padding-left: 0;
    }

    .form-check-input {
      right: 0;
    }

    .difficulty-level {
      margin-left: auto;
      background-color: $east-bay;
      border-radius: 0.5rem;
      max-width: 7rem;
      min-width: 4rem;
      min-height: 1.6rem;
      color: $sunglow;
    }

    &:not(:first-child) {
      border-top: 1px solid $steel-gray;
    }
  }
}

.rule-info-box {
  border-top: 1px solid $black-rock;
  border-bottom: 1px solid $black-rock;

  p {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $white;
  }
}

.rules-detail-form-group {
  .save-btn, .add-more-btn  {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  .save-btn {
    color: $secondary;
    background-color: rgba($secondary,0.11);

    &:hover, &:focus,&:active {
      color: $secondary;
      background-color: rgba($secondary,0.11);
    }
  }

  .add-more-btn {
    color: $blue-haze;
    background-color: transparent;

    i {
      color: $secondary;
      vertical-align: middle;
    }

    &:hover, &:focus,&:active {
      color: $blue-haze;
      background-color: transparent;
    }
  }
}