@import "~assets/stylesheets/core";

.datepicker-box {

    &::after {
      content: "\e912";
      font-family: "sparkode";
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 32px;
      color: $secondary;
      pointer-events: none;
    }
    &.date-picker-error-wrap{
      &::after {
        right: 24px;
      }
    }
  }

.disable-date-picker{
  cursor: not-allowed;
}

.datepicker-popper-box {
  
    .react-datepicker, .react-datepicker__time {
      background:$ebony-clay;
    }
  
    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
      cursor: default;
      color: #ccc !important;
    }
  
    .react-datepicker__header {
      background: $black-rock;
    } 
    .react-datepicker__time {
      color: $white;
    }
  
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
      color: $white;
    }
  
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: $primary;
    }
  
    .react-datepicker__day--selected {
      background-color: $primary;
    }
  
    .react-datepicker__day--today{
      color: $secondary;
    }
  
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover, .react-datepicker__day:hover {
      background: rgba($primary, 0.5);
    }
  
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background: rgba($primary, 0.40);
    }
  
    .react-datepicker__month-container {
      border-right: 1px solid #aeaeae;
    }
  
    .react-datepicker__time-container {
      border-left: unset;
    }
}
