@import "~assets/stylesheets/core";

.confirmation-modal {
  background-color: rgb(20, 18, 18);
  border-radius: 0.25rem;
  .modal-body, .modal-header, .modal-footer {
    background-color: $ebony-clay;
  }
}
.report-icon {
  color: $secondary;
  cursor: pointer;
}
.download-icon-box{
  background-color: none !important;
  background: none !important;
  border-style: none !important;
  .download-icon {
    color: $secondary;
    font-size: 2.1rem;
    vertical-align: middle;
  }
}

.dropdown-menu {
  .dropdown-item {
    font-size: 1rem;
    padding: 10px;
    line-height: 0.75rem;

    &:hover, &:focus, &:active {
      color: $white;
      background-color: $ebony-clay;
    }

    &:focus-visible {
      outline: none;
    }

    &:disabled{
      color: #adb5bd !important;
    }
  }
}

.result{
  width: 170px !important;
  margin-top: 8px;
}

.email-react-select{
  .css-1w9vlm2-control{
  background-color: $black-rock !important;
  }
  .css-1egh3yk-control{
    background-color: $black-rock !important;
  }
}
