@mixin scrollbars() {
  scrollbar-width: thin;
  scrollbar-color: $black-rock transparent;

  &::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-rock;
  }

  &::-webkit-scrollbar-track {
    background: $body-bg;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $black-rock;
    scrollbar-track-color: transparent;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}