@import "~assets/stylesheets/core";

.dark {
  background-color: $steel-gray;
  font-family: "Montserrat";
  padding-top: 100px;
  height: 100vh;
  width: 100%;
}

.custom-padding{
  padding-top: 10%;
}

.title-color {
  font-size: 50px;
  color: $secondary !important;
}

.custom-color.btn-style {
  background-color: $primary;
  border: none;
  color: white;
}

  