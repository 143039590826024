@import "~assets/stylesheets/core";

.button-wrapper {
  background-color: $black-rock;

  .form-control {
    flex: 1;
  }
}

.icon-curson {
  cursor: pointer;
}
