@import "~assets/stylesheets/core";

.count-pill-box {
  background: transparent linear-gradient(177deg, rgba(62, 65, 79, 0) 0%, rgb(62, 65, 79) 100%) 0% 0% no-repeat padding-box;
  border-radius: 0 0 0.375rem 0.375rem;
  padding: 0 0.375rem;

  span {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    color: $white;
    font-weight: 600;
  }

  label {
    font-size: 0.5625rem;
    line-height: 0.875rem;
    color: $blue-haze;
  }
}