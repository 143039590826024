@import "~assets/stylesheets/core";

.card-wrapper {
  background: $comet !important;
  font-size: 1.5rem;
  box-shadow: 0px 6px 50px 0px $baby-blue;

  .title-style {
    font-size: 4.5rem;
  }

  .sub-title-style {
    font-size: 2rem;
  }

  .icon-list {
    font-size: 5rem;
    .icon-wrapper {
      cursor: pointer;
      &:focus, &:active {
        color: $deep-lilac;
      }
    }

    .icon-selector{
      color: $baby-blue;
    }
  }
}