@import "~assets/stylesheets/core";

.add-question-section {
  height: 100vh;
}

.question-col-wrapper {

  .question-col {
    &:first-child {
      flex: 0 0 70%;
    }
    &:last-child {
      flex: 0 0 30%;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: url('../../../../assets/images/divider@2x.png') no-repeat;
        width: 4px;
        height: 100%;
        background-size: cover;
        background-position: top;
      }
    }
  }
}

.add-question-form {
  .form-group {
    label {
      color: $secondary;
      font-size: 1rem;
      line-height: 1.5625rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
}
.form-group-col-wrapper {
  
  margin: 0 -0.75rem 1rem;
  .form-group-col {
    .form-group {
      padding: 0 0.75rem;
    }

    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 0 0 60%;
    }
  }
}
.width-fit-content{
  width: fit-content;
}

.question-diagram-wrapper {
  display: block;
  justify-content: right;
  height: 250px;
  width: 500px;
  align-items: center;
  margin: auto;

  .image-wrapper {
    min-height: 250px;
    max-height: 250px;
    min-width: 500px;
    max-width: 500px;
    border-radius: 18px;
    border: 1px;
    object-fit: contain;
  }
}

.height100 {
  height: 100px !important;
}
