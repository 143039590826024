@import "~assets/stylesheets/core";

.search-input-box {
  background-color: $black-rock;
  border-radius: 0.25rem;

  .form-control {
     max-width: 17rem;
     min-width: 10rem;
     line-height: 1.0625rem;
  }
  
  .icon-curson {
    cursor: pointer;
  }

  .input-group-append {
    i{ 
      font-size:1.25rem;
      color: $white;
    }
  }
}