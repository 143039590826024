@import "~assets/stylesheets/core";

.search-modal {

  &.left_modal {
    .modal-content {
      border-radius: 0;
      border: none;
      height: 100vh !important;
      background-color: $steel-gray;
    }
  }

}

.sp-col {
  min-width:25.5rem;
  height: 100vh;
  position: relative;
  flex: 1;

  &:not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: url('../../../../assets/images/divider@2x.png') no-repeat;
        width: 4px;
        height: 100%;
        background-size: cover;
        background-position: top;
      }
  }
}

.sp-col-header {
  h3 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: $white;
  }

  span {
    font-size: 1.875rem;
    line-height: 2.3125rem;
    color: $white;
    background-color: $ebony-clay;
    border-radius: 0.375rem;
  }

  h3 {
    &.-upcoming {
        color:$sunglow;
      }
    &.-completed {
      color:$medium-orchid;
    }
  }
}
