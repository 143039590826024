// Color system
$white:    #fff;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
$black:    #000;

// $grays: () !default;
// $grays: map-merge(
//   (
//     "100": $gray-100,
//     "200": $gray-200,
//     "300": $gray-300,
//     "400": $gray-400,
//     "500": $gray-500,
//     "600": $gray-600,
//     "700": $gray-700,
//     "800": $gray-800,
//     "900": $gray-900
//   ),
//   $grays
// );

// Custom Theme Colors
$gray13: #202020;
$gray24: #2C2F3D;
$ebony-clay: #252836;
$steel-gray: #1F1D2B;
$crow: #17081B;
$deep-lilac: #B94FC2;
$medium-orchid:#DE5CE9;
$baby-blue: #39FAFF;
$turquoise-blue: #1bd4d9;
$blue-haze: #B2ADCF;
$black-rock: #2D303E;
$comet: #666978;
$sunglow:#FFC41F;
$east-bay:#4F5675;
$corn-flower-blue:#4A466A;
$green: green;
$orange-yellow: #BCDC5A;
$yellow: yellow;
$orange: orange;
$red: red;
$light-green: #05ea05;


// $blue:    #007bff !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #28a745 !default;
// $teal:    #20c997 !default;
// $cyan:    #17a2b8 !default;

// $colors: () !default;
// $colors: map-merge(
//   (
//     "blue":       $blue,
//     "indigo":     $indigo,
//     "purple":     $purple,
//     "pink":       $pink,
//     "red":        $red,
//     "orange":     $orange,
//     "yellow":     $yellow,
//     "green":      $green,
//     "teal":       $teal,
//     "cyan":       $cyan,
//     "white":      $white,
//     "gray":       $gray-600,
//     "gray-dark":  $gray-800
//   ),
//   $colors
// );

$primary:      $deep-lilac;
$secondary:     $baby-blue;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
    
  ),
  $theme-colors
);


// Body
$body-bg:                   $steel-gray;
$body-color:                $white;
$text-color:                $ebony-clay;

$font-family-saira: 'Saira', sans-serif;
$font-family-base: $font-family-saira;

//inputs Forms

$input-padding-y:                       0.75rem ;
$input-padding-x:                       1rem;
$input-font-family:                     $font-family-saira;
$input-font-size:                       0.875rem;
$input-font-weight:                     600 ;
$input-line-height:                     1.375rem;

$input-bg:                              $black-rock;
$input-color:                           $white;
$input-border-color:                    $input-bg;
$input-placeholder-color:               $comet;
$input-box-shadow:                      none;
$input-focus-bg:                        rgba(147, 139, 200, 0.2);
$input-focus-border-color:              rgba(147, 139, 200, 0.2);
$input-focus-box-shadow:                 none;
$input-height:                          auto;


// Buttons

$btn-padding-y:               0.5rem;
$btn-padding-x:              0.5rem;
$btn-font-size:               1.125rem;
$btn-line-height:             1.75rem;


$btn-border-radius: 0.25rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

//Modal

$modal-backdrop-bg:                 $body-bg;
$modal-backdrop-opacity:            0.25;