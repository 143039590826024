@import "~assets/stylesheets/core";

.problem-details-section {
  .problem-description-wrap {
    flex: 0 0 40%;
    border-right: 2px solid $black-rock;
  }

  .problem-test-case-wrap {
    flex: 0 0 60%;
    padding: 1rem 2.5rem;
  }

  .description-wrapper {
    padding: 1.5rem 1.5rem 2rem;
    height: 100vh;

    h5 {
        font-size: 1.5rem;
        line-height: 2.375rem;
        color: $white;
    }

    .description-label {
      font-size: 1rem;
      line-height: 1.5625rem;
      font-weight: 500;
      color: $secondary;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: $white;
      font-weight: 500;
      white-space: pre-line;
    }
  }

  .edit-btn {
    background-color: $black-rock;
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 0.25rem 1rem;
    color: $white;
    i {
      color: $secondary;
      font-size: 1.25rem;
      vertical-align: middle;
    }

    &:hover, &:focus, &:active {
      color: $white;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .test-case-wrapper {
    h6 {
      color: $primary;
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
}
.edit-icon-box{
  .pen-icon {
    color: $secondary;
    cursor: pointer;
    font-size: 1.875rem;
    vertical-align: middle;
  }
}
