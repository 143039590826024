@import "~assets/stylesheets/core";

.add-candidate-modal {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: none;
  }
  .modal-content {
    height: 100%;
    min-height: 100%;
    border-radius: 0;
    border: none;
    background: $steel-gray;
  }
    .candidate-modal-header {
      padding: 2rem 1.5rem;

      i {
        color: $secondary;
        font-size: 1.875rem;
        cursor: pointer;

        &:hover, &:focus, &:active {
          background-color: rgba($blue-haze, 0.25);
          border-radius: 0.25rem;
        }
      }
    }

  .candidate-modal-body {
    margin: 0 5%;
    .site-logo-img {
      width: 6.75rem;
      height: auto;
      >img {
        width: 100%;
      }
    }
  }

  .candidate-invitation-wrap {
    .invitation-col {

      &:first-child {
        flex: 0 0 50%;
      }
      
      >label {
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: $white;
        font-weight: 600;
      }

      span {
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $white;
      }

      .upload-file-wrap {
        p {
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: $white;
        }
      }

      .success-message {
        color: green;
      }

      &.-divider-wrap {
        padding: 0 3.75rem;
      }
    }
  }

  .divider-line {
    position: relative;
    &:before , &::after {
      content: '';
      position: absolute;
      left: 0.625rem;
      height: 3rem;
      width: 0.0625rem;
      background: $white;
    }

    &::before {
      top: -4.375rem;
    }

    &::after {
      top: 3em;
    }
  }

  .browse {
    position: relative;
    width: 100%;
    overflow: hidden;

    >label {
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: $secondary;
      border: 1px solid $secondary;
      border-radius: 0.25rem;
      background-color: $steel-gray;
    }

    .form-control-file {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: 0;
    }
  }

  .button-wrapper {
    background-color: $black-rock;
    border-radius: 0.25rem;

    .form-control {
      flex: 1;
    }
  }
}
.credit-points-wrapper {
  color: $blue-haze;
  font-size: 1.750rem;
  .points{
    color: white;
  }
  // line-height: 1.625rem;
}
.refresh-icon-wrapper {
  color: $secondary;
  cursor: pointer;
}
.name {
  color: $secondary;
}

.send-invitaion {
  .send-icon {
    font-size: 1.5rem;
  }
}