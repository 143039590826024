@import "~assets/stylesheets/core";

.login-section {
  background: url('../../../../assets/images/login-bg.png') no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.login-wrapper {
  padding: 0 5rem;
}

