@import "~assets/stylesheets/core";

.login-card-box {
  padding: 4rem 2rem;
  background-color: $crow;
  top: 0;
  right: 200px;
  width: 24rem;
  height: 100vh;

  h2 {
    color: $secondary;
    font-size: 1.75rem;
    line-height: 1.875rem;
    margin-bottom: 2rem;
  }
}


.login-form {
  padding: 2rem 0;

  .notification-message{
    text-align: center;
  }

  .form-group {
    margin-bottom: 2rem;
  
    >label {
      color: $secondary;
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }

  .login-details-box {

    .login-link, .form-check-label {
      font-family: $font-family-saira;
      font-size: 1.00rem;
      line-height: 1.1875rem;
      color: $white;
    }
  }
}