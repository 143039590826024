@import "~assets/stylesheets/core";

.feedback-bank-section {
  padding: 2rem 1rem 0;
  height: 100vh;
}

.feedback-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
 

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }
 }
