@import "~assets/stylesheets/core";

.problem-bank-section {
  padding: 2rem 1rem 0;
  height: 100vh;
}

.problem-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
 

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }
 }

 .problems-content {
  flex: 1;

  .problem-description {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $blue-haze;
    height: 60px;
    overflow-wrap: anywhere;
  }
}

.problem-info {
  border-top: 2px solid $steel-gray;
  border-radius: 0 0 0.25rem 0.25rem;
  // bottom: 0;

  .problem-info-tile {
    background-color: $black-rock;
    flex: 1;
  }

  .problem-tile-box  {
    label {
      font-size:0.75rem ;
      line-height: 1.1875rem;
      color:$blue-haze;   
    }

    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: $white;
    }

    &:first-child {
      flex: 0 0 40%;
    }
    &:not(:first-child) {
      flex: 0 0 60%;
      border-left: 2px solid $steel-gray;
    }
  }
}

.filter-dropdown-btn {
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.875rem;
}

.filter-wrapper {
  justify-content: flex-end;

  .difficulty-filter {
    background-color: $east-bay;
    border-radius: 0.5rem;
    max-width: 7rem;
    cursor: pointer;
    min-height: 1.6rem;
  }

  .close-icon {
    margin-top: 0.10rem;
  }
}

.margin-without-filter {
  margin-top: 2.6rem;
}

.margin-with-filter {
  margin-top: 1rem;
}

.icon-delete {
  color:$secondary;
  font-size: 1.5rem;
  cursor: pointer;
}

.prob-title {
  cursor: pointer;
  overflow-wrap: anywhere;
}
