@import "~assets/stylesheets/core";

.site-logo-wrap {
  width: 10.75rem;
  height: 5.5rem;

  .site-logo {
    max-width: 100%;
    height: auto;
  }
}