@import "~assets/stylesheets/core";

.test-section {
height: 100vh;
}

.test-section-head {
  // padding: 0.5rem 0.5rem 0;

  @include media-breakpoint-up(xl) {
    // padding: 1.5rem 1.5rem 0;
  }
  .test-timing-wrap {
    font-size: 1rem;
    line-height: 1.5;
    color: $corn-flower-blue;
    margin-top: 1.2rem;
  }

  .save-btn-wrapper {
    position: relative;
    min-width: 6rem;
    height: 80%;
    align-items: center;
    border-radius: 0.3rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
    background-color: #7BF9FC38;
    
    span {
      font-size: 1.0rem;
      color: $secondary;
    }
  }

  .save-btn {

    >i {
      font-size: 2.4rem;
      color: $secondary;
      vertical-align: middle;
    }
  }

  .dropdown-wrapper {
    margin-top: 1.2rem;
  }

  .webcam-wrapper {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 7.8125rem;
    height: 3.375rem;
    z-index: 9999;

    @include media-breakpoint-up(xl) {
      width: 11.0625rem;
      height: 4.375rem;
    }

    >video {
      border-radius:0 0 0.8125rem 0.8125rem;
    }
  }
}

.code-wrapper {
  background-color: $steel-gray;
  border-radius: 1.25rem;
  padding: 2.25rem 0 0;

  .save-btn {
    right: 0.5rem;
    top: 0.25rem;

    >i {
      font-size: 2rem;
      color: $secondary;
    }
  }
}

.custom-button-wrapper {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;

  .custom-input-btn {
    background: url('../assets/images/custom-input-bg1.png') no-repeat;
    background-size: cover;
    width:17.5rem;
    height:3rem;

    span {
      color: $white;
      font-size: 0.875rem;
      line-height: 1.875rem;
    }
  }
  .custom-input-section {
    background-color: $steel-gray;
    box-shadow: 0px -7px 6px rgba(0, 0, 0, 0.161);
    border-radius: 1.1875rem 1.1875rem 0 0;
    height: 12.5rem;
    display: none;

    .form-group {
      flex: 1;

      .form-control {
        background-color: transparent;
      }
    }
    .close-button {
      position: unset;
    }

    .run-btn {
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }

  &.toggle-wrapper {
    .custom-input-section {
      display: block;
    }
  }
}

.run-code-btn {
  background: url('../assets/images/run-btn.svg') no-repeat;
  background-size: cover;
  background-position: center;
  width: 3.5rem;
  height: 9rem;
  right: -0.5625rem;
  bottom: 1.5rem;

  &:hover {
    background: url('../assets/images/run-bg-hover.svg') no-repeat;
    background-size: cover;
    background-position: center;
    width: 4.625rem;
    right: -1.6875rem;
  }
}

.finish-test-modal {
  top: 15% !important;
  .modal-content {
    height: 70%;
    min-height: 70%;
    border-radius: 0;
    border: none;
    background: $steel-gray;
  }
}