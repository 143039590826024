@import "~assets/stylesheets/core";

.rules-section {
  padding: 2rem 1rem 0;
  height: 100vh;
}
.icon-pen {
  color:$secondary;
  font-size: 2rem;
  cursor: pointer;
}
.rules-list-wrap {
  padding: 0 1rem;

  .rules-list {
    background-color: $ebony-clay;
    border-radius: 0.25rem;
  }
  .rules-list-item {

    .sequence-no, p{
      color: $white;
    }

    .sequence-no {
      font-size:0.75rem;
      line-height: 0.9375rem;
    }

    p {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.125rem;
    }

    &:not(:first-child) {
      border-top: 1px solid $steel-gray;
    }
  }
}

.rules-section-head {
  margin-bottom: 2.5rem;
}


.edit-rule-box {
  background: transparent linear-gradient(
  284deg
  , $ebony-clay 0%, $east-bay 100%) 0% 0% no-repeat padding-box;
  border-radius:0.25rem;
  box-shadow: 0px -9px 6px rgba(0, 0, 0, 0.161);

  .form-group {
    flex: 1;
    .form-control {
      background-color: transparent;
      border-color: transparent;
      font-size: 1.25rem;
      line-height: 1.125rem;
    }

    .was-validated{
      .form-control:invalid, .form-control.is-invalid {
        border-color: #dc3545;
      }
    } 
  }

  .btn-primary {
    padding: 0.5rem 2rem;
  }

  .btn-link {
    line-height: unset;
    font-size: unset;
    >i{
    color: $secondary;
    font-size: 1.875rem;
  }
  }
}