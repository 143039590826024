$icomoon-font-family: "sparkode" !default;
$icomoon-font-path: "../fonts" !default;
$icon-font-code: "s7l9c0";

$icon-clock: "\e900";
$icon-arrow-down: "\e901";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e903";
$icon-arrow-up: "\e904";
$icon-close: "\e905";
$icon-edit: "\e906";
$icon-check: "\e907";
$icon-home: "\e908";
$icon-menu: "\e909";
$icon-drive: "\e90a";
$icon-notifications: "\e90b";
$icon-options: "\e90c";
$icon-problems-bank: "\e90d";
$icon-profile: "\e90e";
$icon-rules: "\e90f";
$icon-search: "\e910";
$icon-users: "\e911";
$icon-calendar: "\e912";
$icon-right: "\e913";
$icon-left: "\e914";
$icon-plus: "\e915";
$icon-pen: "\e916";
$icon-logout: "\e917";
$icon-save: "\e918";
$icon-report: "\e91a";
$icon-send: "\e919";
$icon-finish: "\e91b";
$icon-java: "\e91c";
$icon-c: "\e927";
$icon-cpp: "\e921";
$icon-ruby: "\e922";
$icon-python: "\e923";
$icon-javascript: "\e924";
$icon-golang: "\e925";
$icon-php: "\e926";
$icon-download: "\e91d";
$icon-assessment: "\e91e";
$icon-star: "\e91f";
$icon-call: "\e920";
$icon-email: "\e928";
$icon-organization: "\e929";
$icon-happy: "\e92a";
$icon-happiness: "\e92b";
$icon-smile: "\e92c";
$icon-sceptic: "\e92d";
$icon-sad: "\e92e";
$icon-refresh: "\e92f";
$icon-feedback: "\e930";
$icon-delete: "\e931";
$icon-mcq: "\e932";
$icon-show-password: "\e933";
$icon-hide-password: "\e934";
$icon-thinking: "\e935";
$icon-copy: "\e936";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icon-font-code}');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icon-font-code}#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$icon-font-code}') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icon-font-code}') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$icon-font-code}##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-drive {
  &:before {
    content: $icon-drive; 
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications; 
  }
}
.icon-options {
  &:before {
    content: $icon-options; 
  }
}
.icon-problems-bank {
  &:before {
    content: $icon-problems-bank; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-rules {
  &:before {
    content: $icon-rules; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-left {
  &:before {
    content: $icon-left; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-pen {
  &:before {
    content: $icon-pen; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-report {
  &:before {
    content: $icon-report; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-finish {
  &:before {
    content: $icon-finish; 
  }
}
// below codes are used for language icons
// java icon
.icon-62 {
  &:before {
    content: $icon-java; 
  }
}
// c icon
.icon-49 {
  &:before {
    content: $icon-c; 
  }
}
//cpp icon
.icon-54 {
  &:before {
    content: $icon-cpp; 
  }
}
// ruby icon
.icon-72 {
  &:before {
    content: $icon-ruby; 
  }
}
// python icon
.icon-71 {
  &:before {
    content: $icon-python; 
  }
}
//javascript icon
.icon-63 {
  &:before {
    content: $icon-javascript; 
  }
}
//golang icon
.icon-60 {
  &:before {
    content: $icon-golang; 
  }
}
//php icon
.icon-php {
  &:before {
    content: $icon-php; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-assessment {
  &:before {
    content: $icon-assessment; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-call {
  &:before {
    content: $icon-call; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-organization {
  &:before {
    content: $icon-organization; 
  }
}
.icon-happy {
  &:before {
    content: $icon-happy; 
  }
}
.icon-happiness {
  &:before {
    content: $icon-happiness; 
  }
}
.icon-smile {
  &:before {
    content: $icon-smile; 
  }
}
.icon-sceptic {
  &:before {
    content: $icon-sceptic; 
  }
}
.icon-sad {
  &:before {
    content: $icon-sad; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-feedback {
  &:before {
    content: $icon-feedback; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-mcq {
  &:before {
    content: $icon-mcq; 
  }
}
.icon-show-password {
  &:before {
    content: $icon-show-password; 
  }
}
.icon-hide-password {
  &:before {
    content: $icon-hide-password; 
  }
}
.icon-thinking {
  &:before {
    content: $icon-thinking; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}

