@import "~assets/stylesheets/core";

.sp-logo {
  width: 6.75rem;
  height: auto;

  >img {
    width: 100%;
  }
}

.sp-sidenav-action-box {
  background-color: $ebony-clay;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;

  i {
    color: $blue-haze;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover, &:focus, &:active {
      background-color: rgba($blue-haze, 0.25);
      border-radius: 0.25rem;
    }
  }
}

.sp-aside-nav-list {

  .sp-aside-nav-item  {
    &:hover, &:active, &:focus {
      background: transparent linear-gradient(
        87deg
        , #484560 0%, rgba(45, 48, 62, 0) 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.375rem;
    }
  }
  .sp-aside-nav-link{

    .arrow-wrapper{
      font-size: 1.4rem;
    }

    .active-dropdown-nav {
        background-color: $deep-lilac;
        color: $steel-gray;
        box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.859);
        border-radius: .25rem;
    }
    
    >i, span {
      color: $blue-haze;
    }

    >i {
      font-size: 1.875rem;
    }

    span {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }

    &.active {

      >i {
      background-color: $deep-lilac;
      color: $steel-gray;
      box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.859);
      border-radius: .25rem;
    }
    }
  }
}

.bottom-link {
  background-color: $steel-gray;

  >img {
    width: 7.5rem;
  }
}

.tour-style {
  color: azure;
}