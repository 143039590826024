@import "~assets/stylesheets/core";

.candidate-login-form-wrap {
  border: 4px solid $black-rock;
  border-radius: 1rem;

    .candidate-login-form-col, .candidate-login-rules-col  {
      flex: 0 0 50%;
    }

    .candidate-login-rules-col {
      background: url('../../assets/images/candidate-login-bg.png') no-repeat;
      background-size: contain;
      background-position: center right;
    }

    .candidate-login-form-col{
      h6 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: $white;
      }
    }
}

.rules-list-wrapper {
  top: 0;
  max-width: 24.6875rem;
  width: 24.6875rem;
  background-color: $gray24;
  box-shadow: 0px 3px 6px rgba($black, 0.161);
  height: calc(100% - 50px);
  margin-left: 2rem;

  .btn-primary {
    bottom: -1rem;
    right: 1rem;
    padding: 0.5rem 2rem;
  }
}

.form-fields {
  
  .form-group-col {
    margin: 0 -0.5rem;
    .form-group {
    flex: 0 0 50%;
    padding: 0 0.5rem;
    }
  }
  label {
    font-size: 1rem;
    line-height: 1.5625rem;
    color: $secondary;
  }
}