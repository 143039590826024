@import "~assets/stylesheets/core";

  .rule-list-content  {
    margin-bottom: 5rem;
  }

  .candidate-rules-item {
    span {
      color: $blue-haze;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    border-bottom: 1px solid $steel-gray;
  }