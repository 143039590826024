@import "~assets/stylesheets/core";

.dark-l {
  background-color: $steel-gray;
  font-family: "Montserrat";
  height: 100vh;
  width: 100%;
}

.custom-padding{
  padding-top: 10%;
}

.title-color {
  font-size: 65px;
  color: $primary;
}

.custom-color.btn-style {
  background-color: $primary;
  border: none;
}

  