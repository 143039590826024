@import "~assets/stylesheets/core";


.close-button {
  padding: 0.375rem !important;
  position: absolute;
  &:hover, &:focus, &:active {
    background-color: rgba($secondary, 0.21);
    text-decoration: none !important;
  }

  i {
    color: $secondary;
    font-size: 1rem;
  }
}