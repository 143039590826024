.page-not-found{
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40rem;
    flex-direction: column;
    
    .error{
        font-size: 10rem;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }

    .error-subTitle{
        padding-top: 1rem;
        font-size: 2rem;
    }

    .error-messsage{
        padding-top: 1rem;
    }
    .link{
        font-size: larger;
    }

}
