@import "~assets/stylesheets/core";

.dark-l {
  background-color: $steel-gray;
  font-family: "Montserrat";
  height: 100vh;
  width: 100%;
}

.custom-padding{
  padding-top: 2%;

  .logo-size{
    width: 15%;
  }

  .listing-items{
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-top: 3%;
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    list-style-type: none;

    .contact-details-wrapper{
    //   border-bottom: 2px solid
    // rgba(57, 250, 255, 0.22);
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 16px;

      .contact-details{
        display: inline-block;
        font-size: 22px;
        line-height: 29px;
        color: #ffffff;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }
}

.title-color {
  font-size: 45px;
  color: white;
}

.supportDetails, .supportDetails:hover {
  color: #ffffff;
}