@import "~assets/stylesheets/core";

.invalid-token-font-style {
  font-family: "Montserrat";
  height: 100vh;
  width: 100%;
}

.text-view {
  color: $secondary ;
  font-size: 2.5rem;
}
