@import "~assets/stylesheets/core";


  .webcam-wrapper {
    top: 0;
    left: 70%;
    transform: translateX(-50%);
    width: 7.8125rem;
    height: 3.375rem;
    z-index: 9999;

    @include media-breakpoint-up(xl) {
      width: 11.0625rem;
      height: 4.375rem;
    }

    >video {
      border-radius:0 0 0.8125rem 0.8125rem;
    }
  }

