@import "~assets/stylesheets/core";

.login-card-box {
  padding: 4rem 2rem;
  background-color: $crow;
  top: 0;
  right: 200px;
  width: 24rem;
  height: 100vh;

  h2 {
    color: $secondary;
    font-size: 1.75rem;
    line-height: 1.875rem;
    margin-bottom: 2rem;
  }
}


.login-form {
  padding: 2rem 0;

  .form-group {
    margin-bottom: 2rem;
  
    >label {
      color: $secondary;
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }

  .login-details-box {

    .forgot-password-link, .form-check-label {
      padding-top: 0.17rem;
      font-family: $font-family-saira;
      font-size: 0.75rem;
      line-height: 1.1875rem;
      color: $white;
    }
  }
}

.password{
  display: block;
  position: relative;
  width: 100%;
  color: $secondary;
  
  &__input{
    display: block;
    text-transform: none;
    width: 100%;
    height: 42px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #B6B6B6;
    font-weight: 400;
    color: $primary;
    
    &:focus, &:active{
      border-color: red;
      outline: 0;
    }
  }

  &__showError {
    cursor: pointer;
    position: absolute;
    bottom: -15.0px;
    right: 2px;
    color: $secondary;
    padding: 25px 0px;
    font-size: 2.8em;
  }

  &__show{
    cursor: pointer;
    position: absolute;
    bottom: -3.0px;
    right: 2px;
    color: $secondary;
    padding: 4px 8px;
    font-size: 2.8em;
  }
}

#exampleCheckFormGroup:focus-within{
  #exampleCheck{
    border: 3px solid white;
  }
  span{
    text-decoration: underline;
  }
}