@import "~assets/stylesheets/core";

.test-description-section {
  height: calc(100vh - 1rem);

  .site-logo {
    width: 6.75rem;
    height: 3.125rem;

    >img {
      width: 100%;
    }
  }

  .problem-content  {
    flex: 1;
    padding-bottom: 1rem;
  }
  .problem-title {
      font-size: 1.125rem;
      line-height: 1.875rem;
      font-weight: 500;
      color: $white;
  }
  .problems-detail-wrapper {
    flex: 1;
  }
  .paginate-wrapper{
    flex-wrap: wrap;
    .page-link{
      position: relative;
      display: block;
      width: 3rem;
      height: 3rem;
      line-height: 1.5rem;
      border-radius: 50%;
      font-size: 1rem;
      color:  #fff;
      text-align: center;
      background: $comet;
      border-color: $comet;
    }

    .answered-question {
      border-width: 0.25rem;
      border-color: $primary;

    }

    .selected-question {
      border-width: 0.25rem;
      border-color: $primary;
      background: $primary;
    }
  }
  .question-section {
    span {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.0rem;
      color: $primary;
    }
    button {
      width: 50%;
      background-color: $ebony-clay;
      border-radius: 0;
      color: $secondary;
      border-color: $steel-gray;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .active-button {
      border-color: $primary;
    }
  }
  .problem-number-wrap {
    p, span {
      color: $white;
    }

    p {
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 500;
    }

    span {
      font-size: 1.25rem;
      line-height: 1.875rem;

      &.total-problem {
        font-size: 0.875rem;
      }
    }

    .icon-right {
      color: $primary;
      font-size: 2rem;
      vertical-align: middle;
    }

    .icon-left {
      color: $primary;
      font-size: 2rem;
      vertical-align: middle;
    }
  }

  .problem-total-time-box {

    position: relative;
    margin-bottom: 2rem;
    span {
      background-color: $steel-gray;
      border-radius: 0.25rem;
      border: 1px solid $secondary;
      color: $secondary;
      font-size: 0.75rem;
      line-height: 0.75rem;

      &::before {
        content: '';
        position: absolute;
        left: -1.5rem;
        top: 50%;
        width: 100%;
        height: 0.125rem;
        background-size: cover;
        z-index: -1;
        background: transparent radial-gradient(closest-side at 28% 50%, #7BFAFD 0%, #78F4F700 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

  .problem-description {
    font-size: 1rem;
    line-height: 1.3125rem;
    color: $blue-haze;
    white-space: pre-line;
  }
}