@import "~assets/stylesheets/core";

.menu-dropdown {
  .btn {
    background: $steel-gray;
    padding: 0.25rem;
    line-height: unset;
    font-size: unset;

    i {
      color: $white;
      font-size: 1rem;
    }
  }
  .btn {
    &:hover, &:focus {
      background: rgba($secondary, 0.322);
    }
  }
}

.toggle-dropdown-menu {

  &.dropdown-menu {
    padding: 0;
    // top: 0.625rem !important;
    min-width: 4.625rem;
    border: none;

    .dropdown-item{
      background: $white;
      color: $gray13;
      font-size: 0.75rem;
      padding: 0;
      text-transform: capitalize;

      >button {
        background: $white;
        color: $gray13;
        font-size: 0.75rem;
        line-height: 1.5rem;
        padding: 0;
        border-radius: 0;
        text-transform: capitalize;
        display: block;
        width: 100%;
        &:hover, &:focus {
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }
}

.filter-toggle-dropdown-menu {

  &.dropdown-menu {
    padding: 0;
    // top: 0.625rem !important;
    min-width: 8.625rem;
    border: none;
    // transform: translate3d(247px, 163px, 1px);

    .dropdown-item{
      background: $white;
      color: $gray13;
      font-size: 0.75rem;
      padding: 0.25rem 1rem;
      text-transform: capitalize;

      >button {
        background: $white;
        color: $gray13;
        font-size: 1.00rem;
        line-height: 2.5rem;
        padding: 0.55rem 1rem;
        text-transform: capitalize;
        border: none;
        &:hover, &:focus {
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }
}


.filter-menu {
  .btn {
    background: $blue-haze;
    padding: 0.25rem;
    line-height: unset;
    font-size: unset;

    span {
      color: $gray13;
      font-size: 1.3rem;
    }
  }
  .btn {
    &:hover, &:focus {
      background: rgba($primary, 0.322);
      span {
        color: $white;
        font-size: 1.3rem;
      }
    }
  }
}
