@import "~assets/stylesheets/core";

.report-card {
  background-color: $ebony-clay;
  border-radius: 0.25rem;
  padding: 1rem;
}

.headers {
  color: $primary;
}

.progress_bar{
  width:100%;
  background: #B2ADCF;
}
.problemDescription{
  padding-left: 20px;
  white-space: pre-wrap;
}

.tableStyle {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;

}

.srNo{
  width: 7%;
}
.status{
  width: 10%;
}
.option{
  width: 20%;
}
.marksObtained{
  width: 15%;
}

.problem-image-wrapper {
  display: block;
  align-content: left;

  .problem-image {
    min-height: 250px;
    max-height: 250px;
    min-width: 500px;
    max-width: 500px;
    border: 1px;
    padding-top: 10px;
  }
}

.image-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-wrap: anywhere;
}

.icon-click{
  cursor: pointer;
}