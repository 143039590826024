@import "~assets/stylesheets/core";

.card-feedback {
  font-size: 1rem;
  line-height: 1.0625rem;
  color: $blue-haze;
  min-width: 78%;
}

.feedback-content {
  padding: 0 1.5rem 0.75rem;

  .card-height{
    height: 4rem;
    overflow-y: auto;
    scrollbar-width: none;
  }
}

.feedback-date-details {

  flex: 1;
  i {
    font-size: 1.125rem;
  }
  .icon-happy {
    color: $green;
  }
  .icon-happiness {
    color: $orange-yellow;
  }
  .icon-smile {
    color: $yellow;
  }
  .icon-sceptic {
    color: $orange;
  }
  .icon-sad {
    color: $red;
  }
  .icon-arrow-right {
    color:$secondary;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    color: $secondary;
  }
  .feedback-icon {
    font-size: 2.75rem;
  }
  .name-width {
    overflow-x: hidden;
    max-width: 8rem;
  }
  .delete-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .button-color {
    &:hover, &:focus, &:active {
      background: transparent linear-gradient(180deg, #FFC92400 0%, #FFC92454 82%) 0% 0% no-repeat padding-box;
      color: aqua;
    }
  }
}

.tile-button-wrapper {
  border-top: 1px solid $steel-gray;
  .tile-btn {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: $white;
    flex: 1;
    background: transparent linear-gradient(180deg, #FFC92400 0%, #FFC92454 82%, #FFC924 89%, #FFC924 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: luminosity;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;

    &:first-child {
      border-radius: 0 0 0 0.25rem;
    }

    &:not(:first-child) {
      border-left: 1px solid $steel-gray;
      border-radius: 0 0 0.25rem  0;
    }

    &.-candidates-btn {
      &:hover, &:focus, &:active {
        background: transparent linear-gradient(180deg, #7BFAFD00 0%, #7BF9FC38 72%, #7BF9FCC7 85%, #79F6F9FC 100%) 0% 0% no-repeat padding-box;
      }
    }


    &.-result-btn {
      &:hover, &:focus, &:active {
      background: transparent linear-gradient(180deg, #FFC92400 0%, #FFC92454 82%, #FFC924 89%, #FFC924 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

}