@import "~assets/stylesheets/core";

.button-wrapper {
  background-color: $black-rock;

  .form-control {
    flex: 1;
  }
}

.search-icon{
  cursor: pointer;
}

.filter-box-style{
  border-style: solid;
  border-width: 1px;
}

.edit-table{
  width: 40% !important;
  border-color: none !important;

  tr {
    td {
      border: none;
    }
  }
}


.drive-details{
  flex: 0 0 50%;
  padding: 0 1rem;
}

.drive-details-wrap
{
  margin: 0 -1rem;
}

.section-sub-heading{
  font-weight: 500;
}

.back-button
{
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);

  .back-page-arrow{
      color: aqua !important;
  }
  &:active, &:hover, &:focus {
    .back-page-arrow{
      color: $ebony-clay !important;
    }
  }
}
