@import "~assets/stylesheets/core";

.mcq-component-wrapper {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $steel-gray;
  border-radius: 0.7rem;
  overflow-y: auto;

  pre{
    max-height: 200px;
    color: white;
    white-space: pre-wrap;
    font-size: 1rem;
  }
  .flex-option2 {
    flex: 0 0 25%;
  }
  .question-wrapper {
    color: $secondary;
  }
  .options-wrapper{

    .input-chk-wrapper{
      width: 1.625rem;
    }

    .input-checkbox{
      position: relative;
      margin: 0 !important;
    }

    .input-radio-button{
      position: relative;
      margin: 0 !important;
      height: 1.2rem;
      width: 100%;
      cursor: pointer;
    }

    .active-option {
      outline: 3px solid $primary;
      border-radius: 8px;
      padding: 0.25rem;
    }

    .options-diagram-wrapper {
      .image-wrapper {
        width: 200px;
        height: 200px;
        border-radius: 18px;
        border: 1px;
      }
    }
  }

  li{
    list-style-type: decimal;
  }
  span{
    white-space: pre-wrap;
    font-size: 0.9rem;
  }
  .question-text-wrap{
    color: $secondary;
    p {
      overflow: hidden;
      font-size: 1.2rem;

      &::after {
        content: "";
        display: inline-block;
        height: 0.5em;
        vertical-align: bottom;
        width: 100%;
        margin-right: -100%;
        margin-left: 10px;
        border-top: 5px solid #252836;
      }
    }
  }
  
  .question-diagram-wrap{

    .image-wrapper {
      width: 500px;
      height: auto;
      border-radius: 18px;
      border: 1px solid $border-color;
    }
  }
}