@import "~assets/stylesheets/core";

.question-modal {

    .image-pop-up {
      width: 100%;
      height: auto;
    }
        .modal-header {
          border-bottom: none;
    
          .close {
            font-size: 28px;
          }
        }
}
