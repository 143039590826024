@import "~assets/stylesheets/core";

.action-tile-wrap {
  .tile-title {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    color: $blue-haze;
  }
  .icon-pen {
    font-size: 1.75rem !important;
  }

  .action-box {
    i {
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
}

.drive-count-details {
  margin: 0 -0.25rem;
}

.credits-wrapper {
  font-size: 1.25rem;
}

.difficulty-filter {
  color: $sunglow;
  text-transform: capitalize;
}

.param-heading {
  font-size: 0.85rem;
  line-height: 1.0625rem;
  color: $sunglow;
}
