@import "~assets/stylesheets/core";

.test-page-wrapper{
  background-color: $ebony-clay;
  height: 100vh;

  .test-action {
    // background-color: $ebony-clay;
    // position:relative;
    // z-index:999;
    // box-shadow: 1px 1px 5px 1px $blue-haze;
  }

.test-page-section {
  // background-color: $ebony-clay;

  .test-col {
    flex: 0 0 20.625rem;
    max-width: 20.625rem;
    // height: 100vh;
    position:relative;

    @include media-breakpoint-up(xl) {
      flex: 0 0 30.625rem;
      max-width: 30.625rem;
    }

    &.-code-col {
      flex: 1;
      max-width: 100%;
    }
  }
}
}

.output-section {
  // padding: 1.5rem 1.5rem 1.5rem;

  h6 {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: $white;
    text-transform: capitalize;
    margin-top: 3.75rem;
  }
}