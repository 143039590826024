@import "~assets/stylesheets/core";

.bg-card{
  background-color: $steel-gray !important;
}

.navitem-style{
  font-weight: bold;
  font-size: 25px;

  .active {
    color: $primary;
    text-decoration: underline;

    &:hover{
      color: $primary;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  a{
    color: white;

    &:hover{
      color: white;
      cursor: pointer;
    }
  }
}
