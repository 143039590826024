@import "~assets/stylesheets/core";

.edit-margin {
  margin-left: 1rem;
}

.span-style {
  color: $blue-haze;
}

.edit-icon{
  font-size: 2rem;
  cursor: pointer;
}