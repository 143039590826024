.admin-login-height{
  height: 100vh;
}
.admin-login-left{
  font-family: "Montserrat";
  background-color: #1E1E1E;
  height: 100vh;
  width: 100% ;
}
.admin-login-right{
  font-family: "Montserrat";
  background-color: #218838;
  height: 100vh;
  width: 100% ;
}
.title-css {
  font-size: 40px;
  color: #1E1E1E;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  padding-bottom : 40px;
}
.textColor{
  color: #1E1E1E;
}
