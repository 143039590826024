@import "~assets/stylesheets/core";

.technology-dropdown {
  width: 13.4375rem;
  background: url('../../assets/images/dropdown-bg.svg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 1.75rem;
  .dropdown-toggle {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: $white;
    width: 100%;
    &::after {
      display: none;
    }

    i {
      color: $secondary;
      font-size: 2rem;
      vertical-align: middle;
    }

    &:hover , &:focus, &:active {
      color: $white;
    }

    span {
      width: 5rem;
    }

    .technology-icon{
      font-size: 1.5rem;
    }
  }

  .dropdown-menu {
    background-color: $steel-gray;
    border: 1px solid $ebony-clay;

    .dropdown-item {
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: $white;
      background-color: $steel-gray;
      border-bottom: 2px solid $ebony-clay;

      .technology-icon{
        font-size: 1rem;
      }

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $steel-gray;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}